import { PROMOTION_TYPE } from 'constants/product';

export const getPromotionTranslationKey = (promotionType?: string) => {
  switch (promotionType) {
    case PROMOTION_TYPE.GENERIC:
      return 'general_promotion_promo';
    case PROMOTION_TYPE.CASHBACK:
      return 'general_promotion_cashback';
    case PROMOTION_TYPE.FREE_PRODUCT:
      return 'general_promotion_free_product';
    default:
      return undefined;
  }
};
