import { EnergyLabel } from 'components/Layout';
import { PriceNew, StrikePriceNew } from 'components/PriceNew/PriceNew';
import { useProductCardLoaded } from 'hooks/useProductCardLoaded';
import { useProductCardViewed } from 'hooks/useProductCardViewed';
import { useIntl } from 'react-intl';
import {
  ATPMessage,
  Button,
  Checkbox,
  Countdown,
  Link,
  ProductCardDescription,
  ProductCardImage,
  ProductCardImageWrapper,
  ProductCardSigning,
  ProductCardTitle,
  ProductCardWishlistIconButton,
  ProductPills,
  Rating,
  RatingMessage,
  Skeleton,
  Text,
} from 'ui';
import { cn } from 'utils/cn';
import { ProductCardProps } from './types';

const ProductCardSkeleton = ({ className }: { className?: string }) => (
  <div className={cn(className)}>
    <Skeleton className="h-[185px] w-full" />
    <div className="flex w-full flex-grow flex-col gap-2">
      <Text loading type="h4" className="md:h-10" />
      <Skeleton className="h-6 w-1/2" />
      <Skeleton className="h-5 w-1/4" />
      <Skeleton className="h-5 w-3/4" />
    </div>
  </div>
);

const ProductCard = ({
  atp,
  attributes,
  buttonLabel,
  className,
  code,
  disabledAddToCartButton,
  endDate,
  energyLabel,
  href,
  image,
  inWishlist = false,
  isCompared,
  loading,
  onAddToCart,
  onClick,
  onCompare,
  onLoaded,
  onViewed,
  onWishlistClick,
  pills,
  price,
  product,
  rating,
  signings,
  sponsoredSigning,
  strikePrice,
  title,
}: ProductCardProps) => {
  const { formatMessage } = useIntl();
  const { ref } = useProductCardViewed(onViewed);
  useProductCardLoaded(onLoaded);

  return (
    <div
      ref={ref}
      className={cn(
        'relative flex flex-row items-start justify-center gap-4 py-3 md:flex-col',
        'md:focus-visible-within-border md:h-full md:rounded md:border md:border-solid md:border-transparent md:bg-card md:p-3 md:text-foreground md:hover:border-accent-40 md:hover:drop-shadow-base',
        sponsoredSigning ? 'pt-8 md:pt-3' : '',
        className,
      )}
    >
      <div className="flex w-[100px] flex-shrink-0 flex-col gap-3 md:relative md:w-full">
        <div className="flex flex-col gap-3">
          {!!sponsoredSigning && (
            <ProductCardSigning className="absolute right-0 top-0 z-base" signing={sponsoredSigning} />
          )}
          {!!signings?.length && (
            <div
              className={cn(
                'absolute z-base flex flex-col gap-1 md:right-0 md:items-end',
                !!sponsoredSigning && 'md:top-8',
              )}
            >
              {signings?.map((signing) => <ProductCardSigning key={signing.id} signing={signing} />)}
            </div>
          )}
        </div>

        <Link href={href} onClick={onClick}>
          <ProductCardImageWrapper className="mt-3 md:mt-5">
            {image && (
              <ProductCardImage
                useNext
                fill
                nextSizes={{ default: 300 }}
                src={image.src}
                alt={image.alt}
                className="object-top"
              />
            )}
          </ProductCardImageWrapper>
        </Link>

        {energyLabel && (
          <EnergyLabel
            energyLabel={energyLabel}
            useNewEnergyLabel={energyLabel?.new}
            flipHorizontal
            className="absolute left-0 top-1/2 -translate-y-1/2 md:pt-8"
          />
        )}
      </div>

      <div className="flex w-full flex-grow flex-col gap-2">
        <ProductCardTitle className="md:h-10" onClick={onClick} href={href}>
          {title}
        </ProductCardTitle>

        <div className="flex items-center gap-1">
          <Rating>{rating.value}</Rating>
          <RatingMessage>
            {rating.quantity}{' '}
            {formatMessage({
              id: rating.quantity === 1 ? 'product_card_singular_rating_message' : 'product_card_plural_rating_message',
            })}
          </RatingMessage>
        </div>

        {!!price && (
          <div className="flex items-center gap-2.5">
            <PriceNew>{price}</PriceNew>
            {!!strikePrice && <StrikePriceNew>{strikePrice}</StrikePriceNew>}
          </div>
        )}

        {!!pills?.length && <ProductPills pills={pills} />}

        {!!atp?.label && product && (
          <ATPMessage
            variant={atp.variant}
            product={product}
            preOrderLabel={atp?.preOrderLabel}
            canBeSold={atp.canBeSold}
            availableInStores={atp.availableInStores}
          >
            {atp.label}
          </ATPMessage>
        )}

        {endDate && (
          <Countdown
            date={endDate}
            dayLabel={formatMessage({ id: 'product_countdown_days' })}
            hourLabel={formatMessage({ id: 'product_countdown_hours' })}
            minuteLabel={formatMessage({ id: 'product_countdown_minutes' })}
            secondLabel={formatMessage({ id: 'product_countdown_seconds' })}
          />
        )}

        {!!attributes?.length && <ProductCardDescription>{attributes}</ProductCardDescription>}

        <div className="flex flex-row justify-between gap-2 md:mt-auto md:flex-col md:gap-4">
          {onCompare && (
            <div className="flex items-center gap-2">
              <Checkbox
                checked={isCompared}
                onClick={onCompare}
                value={`${code}-checkbox`}
                size="small"
                id={`${code}-checkbox`}
              />
              <Text tag="label" htmlFor={`${code}-checkbox`} type="small">
                {formatMessage({ id: 'product_compare_checkbox_text' })}
              </Text>
            </div>
          )}

          <div className="flex flex-row items-center gap-2 md:items-end">
            <ProductCardWishlistIconButton
              inWishlist={inWishlist}
              onWishlistClick={onWishlistClick}
              className="md:absolute md:top-3"
            />
            {onAddToCart && (
              <Button
                loading={loading}
                disabled={disabledAddToCartButton}
                onClick={onAddToCart}
                className="md:w-full"
                icon={{ name: 'cart-shopping', styling: 'far' }}
              >
                <div className="hidden md:block">{buttonLabel}</div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductCard, ProductCardSkeleton };
