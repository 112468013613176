import { useIntersectionObserver } from 'usehooks-ts';

export const useProductCardViewed = (onViewed?: () => void) => {
  const onIntersectChange = (isIntersecting: boolean) => {
    if (isIntersecting) {
      onViewed?.();
    }
  };

  return useIntersectionObserver({
    freezeOnceVisible: true,
    onChange: onIntersectChange,
    threshold: 0.5,
  });
};
